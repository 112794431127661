import React from "react";

import { Link } from 'gatsby';

import Header from '../components/header';
import Footer from '../components/footer';
import Meta from '../components/meta';

export default () => 
  <div className="vh-100">
    <Meta title="Sitemap" />
    <Header darkFont={true}></Header>
    <div className="p-5 off-white-background container-fluid">
      <div className="row justify-content-center py-5 mt-5">
        <div className="col-md-8 col-lg-6 col-xl-5 px-0 text-center mt-5">
          <h1 className="text-dark serif d-inline-block">SITEMAP</h1>
          <hr className="col-1 horizontal-divider mb-5"></hr>

          <Link to="/about-us" ><h4 className="serif text-dark font-weight-bold">About Us</h4></Link>
          <Link to="/products/slabs-and-surfaces" ><h4 className="serif text-dark font-weight-bold">Slabs &amp; Surfaces</h4></Link>
          <Link to="/products/feature-walls" ><h4 className="serif text-dark font-weight-bold">Wall Features</h4></Link>
          <h4 className="serif text-dark font-weight-bold">Furniture</h4>
          <h4 className="serif text-dark font-weight-bold">Lighting</h4>
          <Link to="/products/gemstone" ><h4 className="serif text-dark font-weight-bold">Gemstone</h4></Link>
          <Link to="/contact" ><h4 className="serif text-dark font-weight-bold">Contact Us</h4></Link>
          <Link to="/faqs" ><h4 className="serif text-dark font-weight-bold">FAQs</h4></Link>
          <Link to="/privacy-policy" ><h4 className="serif text-dark font-weight-bold">Privacy Policy</h4></Link>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
